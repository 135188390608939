import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';

const GooglePlacesAutocompleteInput = ({
  modalState,
  setModalState,
  fieldConfig,
  airportTransferType,
  vegasLimoTransferType,
  cartKey,
}: any) => {

  const airportTransferValue = modalState?.[fieldConfig.name]
    ? {
        label: modalState[fieldConfig.name],
        value: JSON.stringify(modalState[fieldConfig.placeValueName]),
      }
    : '';
  
  const vegasLimoTransferValue = modalState?.[fieldConfig.name]
    ? {
        label: modalState[fieldConfig.name],
        value: JSON.stringify(modalState[fieldConfig.placeValueName]),
      }
    : '';

  const cardValue = modalState.cartData?.[fieldConfig.name]
    ? {
        label: modalState.cartData?.[fieldConfig.name],
        value: JSON.stringify(modalState.cartData?.[fieldConfig.placeValueName]),
      }
    : '';
 
  const handleAirportTransferCardChange = (place: any) => {
    if (place?.label) {
      geocodeByAddress(place.label)
        .then((results) => getLatLng(results[0]))
        .then(async ({ lat, lng }) => {
          const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;
          const additionalInfo = await googlePlaceAdditionalInfo(place?.value?.place_id);

          if (setModalState) {
            setModalState((state: any) => {
              return {
                ...state,
                cartData: {
                  ...state.cartData,
                  [airportTransferType]: {
                    ...state.cartData[airportTransferType],
                    [fieldConfig.name]: place.label.replaceAll("'", '’'),
                    [fieldConfig.placeLinkName]: placeLink,
                    [fieldConfig.placeValueName]: JSON.stringify(place).replaceAll("'", '’'),
                    venuePhoneNumber: additionalInfo?.internationalPhoneNumber ? additionalInfo?.internationalPhoneNumber : '',
                    website: additionalInfo?.websiteUri ? additionalInfo?.websiteUri : '',
                    venueCity: additionalInfo?.addressComponents?.length ? 
                    additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('locality'))?.longText + ', ' + 
                    additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('administrative_area_level_1'))?.longText + ', ' +
                    additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('postal_code'))?.longText : '',
                    hotelName1: (fieldConfig?.name === 'hotelPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.hotelName1,
                    hotelName2: (fieldConfig?.name === 'hotelPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.hotelName2,
                    airportName1: (fieldConfig?.name === 'airportPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.airportName1,
                    airportName2: (fieldConfig?.name === 'airportPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.airportName2,
                    pickUpName1: (fieldConfig?.name === 'pickUpPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.pickUpName1,
                    pickUpName2: (fieldConfig?.name === 'pickUpPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.pickUpName2,
                    dropOffName1: (fieldConfig?.name === 'dropOffPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.dropOffName1,
                    dropOffName2: (fieldConfig?.name === 'dropOffPlaceName' && additionalInfo?.displayName?.text) ? additionalInfo?.displayName?.text : state?.cartData[airportTransferType]?.dropOffName2,
                  },
                },
              };
            });
          }
        })
        .catch((error) => console.error(error));
    } else {
      if (setModalState) {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [airportTransferType]: {
                ...state.cartData[airportTransferType],
                [fieldConfig.name]: '',
                [fieldConfig.placeLinkName]: '',
                [fieldConfig.placeValueName]: '',
                ...(fieldConfig.name === 'pickUpPlaceName' && { pickUpName1: '', pickUpName2: '', pickUpPlaceLink: '' }),
                ...(fieldConfig.name === 'airportPlaceName' && { airportName1: '', airportName2: '', airportPlaceLink: '' }), 
                ...(fieldConfig.name === 'hotelPlaceName' && { hotelName1: '', hotelName2: '', hotelPlaceLink: '' }),
                ...(fieldConfig.name === 'dropOffPlaceName' && { dropOffName1: '', dropOffName2: '', dropOffPlaceLink: '' }),
              },
            },
          };
        });
      }
    }
  };
  
  const handleVegasLimoTransferCardChange = (place: any) => {
    if (place?.label) {
      geocodeByAddress(place.label)
        .then((results) => getLatLng(results[0]))
        .then(async ({ lat, lng }) => {
          const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;
          const additionalInfo = await googlePlaceAdditionalInfo(place?.value?.place_id);
          if (setModalState) {
            setModalState((state: any) => {
              return {
                ...state,
                cartData: {
                  ...state.cartData,
                  [vegasLimoTransferType]: {
                    ...state.cartData[vegasLimoTransferType],
                    ...(fieldConfig.name === 'dropOffAddress1' && { 
                      dropOffName1: additionalInfo?.displayName?.text || '',
                      dropOffPlaceLink: placeLink || '',
                      dropOffPlaceValue: JSON.stringify(place).replaceAll("'", '’'),
                     }),
                    ...(fieldConfig.name === 'pickUpAddress1' && { 
                    pickUpName1: additionalInfo?.displayName?.text || '',
                    pickUpPlaceLink: placeLink || '',
                    pickUpPlaceValue: JSON.stringify(place).replaceAll("'", '’'),
                    }),
                    ...(fieldConfig.name === 'dropOffAddress2' && { 
                      dropOffName2: additionalInfo?.displayName?.text || '',
                      dropOffPlaceLink: placeLink || '',
                      dropOffPlaceValue: JSON.stringify(place).replaceAll("'", '’'),
                    }),
                    ...(fieldConfig.name === 'pickUpAddress2' && { 
                      pickUpName2: additionalInfo?.displayName?.text || '',
                      pickUpPlaceLink: placeLink || '',
                      pickUpPlaceValue: JSON.stringify(place).replaceAll("'", '’'),
                      }),
                    [fieldConfig.name]: place.label.replaceAll("'", '’'),
                  },
                },
              };
            });
          }
        })
        .catch((error) => console.error(error));
    } else {
      if (setModalState) {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [vegasLimoTransferType]: {
                ...state.cartData[vegasLimoTransferType],
                [fieldConfig.name]: '',
                ...(fieldConfig.name === 'dropOffAddress1' && { dropOffName1: '', dropOffPlaceLink: '', dropOffPlaceValue: '' }),
                ...(fieldConfig.name === 'dropOffAddress2' && { dropOffName2: '', dropOffPlaceLink: '', dropOffPlaceValue: '' }),
                ...(fieldConfig.name === 'pickUpAddress1' && { pickUpName1: '', pickUpPlaceLink: '', pickUpPlaceValue: '' }),
                ...(fieldConfig.name === 'pickUpAddress2' && { pickUpName2: '', pickUpPlaceLink: '', pickUpPlaceValue: '' }),
              },
            },
          };
        });
      }
    }
  };
  
  const googlePlaceAdditionalInfo = async (placeId: string | null | undefined) => {
    if (!process.env.REACT_APP_GOOGLE_PLACES_KEY) return null;
    if (!placeId) return null;
    const placesApi = `https://places.googleapis.com/v1/places/${placeId}?fields=id,displayName,websiteUri,internationalPhoneNumber,addressComponents&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`;

    const response = await fetch(placesApi);

    if (response.ok) {
      return await response.json();
    } else {
      return null;
    }
  }

  const handleCardChange = async (place: any) => {
    if (place?.label) {
      try {
        const results = await geocodeByAddress(place.label);
        const fullAddress = results[0].formatted_address;
        const { lat, lng } = await getLatLng(results[0]);
        const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;
        const additionalInfo = await googlePlaceAdditionalInfo(place?.value?.place_id);
  
        const commonData = {
          [fieldConfig.name]: place.label.replaceAll("'", '’'),
          [fieldConfig.placeLinkName]: placeLink,
          [fieldConfig.placeValueName]: JSON.stringify(place).replaceAll("'", '’'),
        };
  
        if (setModalState) {
          setModalState((state: any) => {
            let cartDataUpdate = {};
            if (cartKey === 'Ticket') {
              cartDataUpdate = {
                ...commonData,
                venue: additionalInfo?.displayName?.text ? additionalInfo?.displayName?.text : '',
                venueAddress: fullAddress.replaceAll("'", '’'),
                venuePhoneNumber: additionalInfo?.internationalPhoneNumber ? additionalInfo.internationalPhoneNumber : '',
                website: additionalInfo?.websiteUri ? additionalInfo.websiteUri : '',
                venueCity: additionalInfo?.addressComponents?.length ? 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('locality'))?.longText + ', ' + 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('administrative_area_level_1'))?.longText + ', ' +
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('postal_code'))?.longText : '',
              };
            } else if (cartKey === 'Dinner') {
              cartDataUpdate = {
                ...commonData,
                hotelName: additionalInfo?.displayName?.text ? additionalInfo?.displayName?.text : '',
                hotelCity: additionalInfo?.addressComponents?.length ? 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('locality'))?.longText + ', ' + 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('administrative_area_level_1'))?.longText + ', ' +
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('postal_code'))?.longText : '',
                hotelPhone: additionalInfo?.internationalPhoneNumber ? additionalInfo.internationalPhoneNumber : '',
                restaurantName: additionalInfo?.displayName?.text ? additionalInfo?.displayName?.text : '',
                restaurantPhoneNumber: additionalInfo?.internationalPhoneNumber?.length ? additionalInfo.internationalPhoneNumber : '',
                restaurantPlaceName: fullAddress.replaceAll("'", '’'),
              };
            } else if (cartKey === 'Hotel') {
              cartDataUpdate = {
                ...commonData,
                hotelName: additionalInfo?.displayName?.text ? additionalInfo?.displayName?.text.replaceAll("'", '’') : '',
                hotelCity: additionalInfo?.addressComponents?.length ? 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('locality'))?.longText + ', ' + 
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('administrative_area_level_1'))?.longText + ', ' +
                additionalInfo.addressComponents.find((item: { types: string | string[]; }) => item.types.includes('postal_code'))?.longText : '',
                hotelPhone: additionalInfo?.internationalPhoneNumber ? additionalInfo.internationalPhoneNumber : '',
                placeName: fullAddress.replaceAll("'", '’'),
              };
            } else if (cartKey === 'Airport Transfer Arrival' || cartKey === 'Airport Transfer Departure') {
              cartDataUpdate = {
                ...commonData,
                pickUpName: additionalInfo?.displayName?.text || '',
                dropOffName: additionalInfo?.displayName?.text || '',
                ...(fieldConfig?.name === 'hotelPlaceName' && {
                  hotelName: additionalInfo?.displayName?.text || '',
                  hotelCity: additionalInfo?.addressComponents?.length
                    ? additionalInfo.addressComponents.find((item: { types: string[] }) => item.types.includes('locality'))?.longText + ', ' +
                      additionalInfo.addressComponents.find((item: { types: string[] }) => item.types.includes('administrative_area_level_1'))?.longText + ', ' +
                      additionalInfo.addressComponents.find((item: { types: string[] }) => item.types.includes('postal_code'))?.longText
                    : '',
                  hotelPhone: additionalInfo?.internationalPhoneNumber || '',
                  ...(fieldConfig.name === 'pickUpAddress' && { pickUpName: additionalInfo?.displayName?.text || '', }),
                }),
                
                ...(fieldConfig?.name === 'airportPlaceName' && {
                  airportName: additionalInfo?.displayName?.text || '',
                }),
                ...(fieldConfig.name === 'dropOffAddress' && { dropOffName: additionalInfo?.displayName?.text || '', }),
              };
            } else if (cartKey === 'Vegas Limo Transfer Arrival' || cartKey === 'Vegas Limo Transfer Departure') {
              cartDataUpdate = {
                ...commonData,
                ...(fieldConfig.name === 'dropOffAddress' && { 
                  dropOffName: additionalInfo?.displayName?.text || '',
                 }),
                ...(fieldConfig.name === 'pickUpAddress' && { pickUpName: additionalInfo?.displayName?.text || '', }),
              };
            } else if (cartKey === 'Transfer') {
              cartDataUpdate = {
                ...commonData,
                ...(fieldConfig.name === 'dropOffPlaceName' && { 
                  dropOffName: additionalInfo?.displayName?.text || '',
                  dropOffPlaceName: fullAddress,
                  dropOffPlaceLink: placeLink,
                 }),
                ...(fieldConfig.name === 'pickUpPlaceName' && { 
                  pickUpName: additionalInfo?.displayName?.text || '',
                  pickUpPlaceName: fullAddress,
                  pickUpPlaceLink: placeLink,
                 }),
              };
            } else if (cartKey === 'Limo') {
              cartDataUpdate = {
                ...commonData,
                ...(fieldConfig.name === 'dropOffPlaceName' && { 
                  dropOffName: additionalInfo?.displayName?.text || '',
                  dropOffPlaceName: fullAddress,
                  dropOffPlaceLink: placeLink,
                 }),
                ...(fieldConfig.name === 'pickUpPlaceName' && { 
                  pickUpName: additionalInfo?.displayName?.text || '',
                  pickUpPlaceName: fullAddress,
                  pickUpPlaceLink: placeLink,
                 }),
              };
            } else {
              cartDataUpdate = {
                ...commonData,
                ...(fieldConfig.name === 'dropOffPlaceName' && { dropOffPlaceName: fullAddress }),
                ...(fieldConfig.name === 'pickUpPlaceName' && { pickUpPlaceName: fullAddress }),
              };
            }
  
            return {
              ...state,
              cartData: {
                ...state.cartData,
                ...cartDataUpdate,
              },
            };
          });
        }
      } catch (error) {
        console.error("Error in handleCardChange:", error);
      }
    } else {
      if (setModalState) {
        setModalState((state: any) => ({
          ...state,
          cartData: {
            ...state.cartData,
            [fieldConfig.name]: '',
            ...(fieldConfig?.placeLabelName && {
              [fieldConfig.placeLabelName]: '',
            }),
            [fieldConfig.placeLinkName]: '',
            [fieldConfig.placeValueName]: '',
          },
        }));
      }
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
      selectProps={{
        value: cartKey === 'Vegas Limo Transfer' ? vegasLimoTransferValue : cartKey === 'Airport Transfer' ? airportTransferValue : cardValue,
        isClearable: true,
        onChange: (selectedValue) => {
          if (selectedValue === null) {
            if (cartKey === 'Vegas Limo Transfer') {
              handleVegasLimoTransferCardChange(null);
            } else if (cartKey === 'Airport Transfer') {
              handleAirportTransferCardChange(null);
            } else {
              handleCardChange(null);
            }
          } else {
            if (cartKey === 'Vegas Limo Transfer') {
              handleVegasLimoTransferCardChange(selectedValue);
            } else if (cartKey === 'Airport Transfer') {
              handleAirportTransferCardChange(selectedValue);
            } else {
              handleCardChange(selectedValue);
            }
          }
        },
        // onChange: cartKey === 'Vegas Limo Transfer' ? handleVegasLimoTransferCardChange : cartKey === 'Airport Transfer' ? handleAirportTransferCardChange : handleCardChange,
        styles: {
          control: (provided: any) => ({
            ...provided,
            padding: '5px',
            border: '0',
            boxShadow: '0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)',
            transition: 'box-shadow .15s ease',
          }),
        },
        placeholder: 'Type and select',
      }}
    />
  );
};

export default GooglePlacesAutocompleteInput;
